import getConfig from 'next/config';
import Head from 'next/head';

import { SeoData } from '@api/seo/types';

export const SeoHead = ({ title, canonical, description, canonicalUrl, image, activePage, totalPages }: SeoData) => {
    const { publicRuntimeConfig } = getConfig();

    return (
        <Head>
            {title && <title>{title}</title>}
            {canonical && canonical !== '' && <link rel="canonical" href={canonical} />}
            {description && <meta name="description" content={description} />}
            {image && <meta name="og:image" content={image} />}
            {title && <meta property="og:title" content={title} />}
            {description && <meta property="og:description" content={description ?? ''} />}
            {canonical && <meta property="og:type" content={canonical?.split('/')[1]} />}
            {canonical && canonicalUrl && publicRuntimeConfig && (
                <meta property="og:url" content={publicRuntimeConfig.host + canonical + '/' + canonicalUrl} />
            )}
            {activePage === 1 && totalPages && activePage < totalPages && (
                <link rel="next" href={canonical + `?page=${Number(activePage) + 1}`} />
            )}
            {activePage && totalPages && activePage > 1 && activePage < totalPages && (
                <>
                    <link rel="next" href={canonical + `?page=${Number(activePage) + 1}`} />
                    <link rel="prev" href={canonical + `?page=${Number(activePage) - 1}`} />
                </>
            )}
            {activePage && totalPages && activePage > 1 && activePage === totalPages && (
                <link rel="prev" href={canonical + `?page=${Number(activePage) - 1}`} />
            )}
        </Head>
    );
};
