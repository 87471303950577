import { Dispatch, SetStateAction } from 'react';

import { YANDEX_COUNTER_ID } from '@scripts/data/different';

export interface ShowPopupProps {
    setIsRegistragionPopup: Dispatch<SetStateAction<boolean>>;
}

const SECOND = 1000;
const TEN_SECONDS = SECOND * 10;
const DAY = 864000000;

export const showPopup = ({ setIsRegistragionPopup }: ShowPopupProps) => {
    const savedValue = window.localStorage.getItem('popupLoad');
    const savedValueDay = window.localStorage.getItem('popupLoadDay');

    const time = new Date().getTime();

    if (!savedValue) {
        window.localStorage.setItem('popupLoad', time.toString());
    }

    if (savedValue) {
        if (time - Number(savedValue) > TEN_SECONDS && !savedValueDay) {
            setIsRegistragionPopup(true);
            if (typeof window.ym !== undefined && typeof window.ym === 'function') {
                window.ym(YANDEX_COUNTER_ID, 'reachGoal', 'popup_register');
            }
        }
    }

    if (savedValueDay && time - Number(savedValueDay) > DAY) {
        setIsRegistragionPopup(true);
        if (typeof window.ym !== undefined && typeof window.ym === 'function') {
            window.ym(YANDEX_COUNTER_ID, 'reachGoal', 'popup_register');
        }
    }
};
