import getConfig from 'next/config';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { ReactNode, useEffect, useMemo, useState } from 'react';

import { ProductDetail } from '@api/catalog';

import { useSeoContext } from '@context/seo';

import { RegistrationPopup } from '@components/RegistratioinPopup';
import LoadWrapper from '@components/controls/LoadWrapper';

import { YANDEX_COUNTER_ID } from '@scripts/data/different';
import { scale } from '@scripts/gds';
import { useGetUserData } from '@scripts/hooks/useGetUserData';

import NotFoundPage from '@components/NotFoundPage/NotFoundPage';
import { SeoHead } from './SeoHead';
import { showPopup } from './util';

const AuthPopup = dynamic(() => import('@components/NewAuth'), { ssr: false });

type PageWrapperProps = {
    h1?: string;
    title?: string;
    error?: string;
    isLoading?: boolean;
    children: ReactNode;
    className?: string;
    seoProps?: Record<string, string>;
    productData?: ProductDetail;
    canonicalUrl?: string;
    isNotFound?: boolean;
    isError?: boolean;
};

const PageWrapper = ({
    isLoading = false,
    error,
    children,
    className,
    productData,
    canonicalUrl,
    seoProps,
    isNotFound = false,
    isError = false,
}: PageWrapperProps) => {
    const renderErrorPage = () => {
        if (isError) return <NotFoundPage title="Что-то пошло не так" text="Что-то пошло не так" />;
        if (isNotFound) return <NotFoundPage />;

        return children;
    };

    const { query, asPath, pathname } = useRouter();

    const seoContextData = useSeoContext();
    const { publicRuntimeConfig } = getConfig();

    const [isRegistrationPopup, setIsRegistragionPopup] = useState(false);
    const [isAuthPopup, setIsAuthPopup] = useState(false);

    const currentRoute = useMemo(() => {
        const path = asPath.indexOf('?') === -1 ? asPath : asPath.split('?')[0];

        return `${publicRuntimeConfig ? publicRuntimeConfig.host : ''}${path}`;
    }, [asPath]);

    const activePage = query.page || (1 as number);

    const canonicalGenerating = () => {
        switch (true) {
            case pathname === '/product/[id]':
                return currentRoute;
            case !!seoContextData.canonical:
                return seoContextData.canonical;
            default:
                return currentRoute;
        }
    };

    const seo = isNotFound
        ? {
              title: 'Страница не найдена',
              description: 'Запрашиваемая страница не существует или была удалена.',
              canonical: canonicalGenerating(),
          }
        : {
              ...seoContextData,
              ...seoProps,
              description: `${seoProps?.description || seoContextData.description}`,
              title: `${seoProps?.title || seoContextData.title}`,
              canonical: seoProps && !!seoProps.canonical ? seoProps.canonical : canonicalGenerating(),
              productData: productData,
              canonicalUrl: canonicalUrl,
              activePage: activePage as number,
          };

    const { user, userData, isLoading: isLoadingUserData } = useGetUserData();

    useEffect(() => {
        if (!user && !userData) {
            showPopup({ setIsRegistragionPopup });
        }
    }, []);

    useEffect(() => {
        if (userData) {
            window.localStorage.removeItem('popupLoad');
            window.localStorage.removeItem('popupLoadDay');
            setIsRegistragionPopup(false);
        }
    }, [userData]);

    return (
        <>
            <SeoHead {...seo} />
            {query.viewport === 'desktop' && isRegistrationPopup && !isLoadingUserData && (
                <RegistrationPopup
                    onClose={() => {
                        setIsRegistragionPopup(false);
                        window.localStorage.setItem('popupLoadDay', new Date().getTime().toString());
                    }}
                    onChangeToRegistration={() => {
                        setIsRegistragionPopup(false);
                        setIsAuthPopup(true);
                        if (typeof window.ym !== undefined && typeof window.ym === 'function') {
                            window.ym(YANDEX_COUNTER_ID, 'reachGoal', 'popup_button_register');
                        }
                    }}
                />
            )}
            <LoadWrapper isLoading={isLoading} error={error}>
                <main
                    css={{
                        padding: 0,
                        paddingBottom: scale(11),
                        height: '100%',
                        minHeight: '50vh',
                    }}
                    className={className}
                >
                    {renderErrorPage()}
                </main>
            </LoadWrapper>
            <AuthPopup isRegistration={true} isOpen={isAuthPopup} closeHandler={() => setIsAuthPopup(false)} />
        </>
    );
};

export default PageWrapper;
